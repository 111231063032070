import {Link} from "react-router-dom";
import React from 'react';
import TimeAgo from 'react-timeago';

const AppCard = props => {
    let truncateLength = 50;
    return (
        <Link to={"/app-detail/" + props.data.id} className="text-decoration-none">
            <div className="card card-hover show-hand mb-1 rounded-3 border-0 shadow-sm">
                <div className="card-body p-0">
                    <div className="p-2">
                        <div className="text-dark">{props.data.name}</div>
                        <div
                            className="text-dark">{props.data.description.substring(0, Math.min(truncateLength, props.data.description.length))}</div>
                        <div className="text-secondary">
                            <small><TimeAgo date={props.data.created_at}/></small>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default AppCard;
