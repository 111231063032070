import React from 'react';
import {Link} from 'react-router-dom';

const NotFoundPage = () => {
    return (
        <React.Fragment>
            <p>Page not found. Goto <Link to="/dashboard">Home Page</Link></p>
        </React.Fragment>
    );
};

export default NotFoundPage;