import React, {useEffect, useState} from "react";
import {config} from "../data/constants";
import {fetchGet, fetchPost} from "../utils/index";
import {useHistory} from "react-router-dom";
import * as Yup from "yup";
import {Formik, Form} from "formik";
import {InputField} from "./common/InputField";
import {TextField} from "./common/TextField";
import {Button} from "./common/Button";

const AddApp = () => {
    const history = useHistory();
    const [nameError, setNameError] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const formValidations = Yup.object({
        name: Yup.string().required("Name is Required"),
        description: Yup.string().required("Description is Required")
    });

    const PostData = (data) => {
        setIsSubmitting(true);
        setNameError(null);
        let url = config.API_URL + "/api/v1/app/";
        fetchPost(url, data).then(res => {
            if (res.success) {
                history.push("/dashboard");
            } else {
                setNameError(res.message);
                setIsSubmitting(false);
            }
        });
    };

    return (
        <>
            <section>
                <div className={"container-fluid"}>
                    <div className={"row justify-content-start d-flex"}>
                        <div className={"col-4"}>
                            <div className={"card rounded shadow"}>
                                <div className={"card-header position-relative"}>
                                    <span className={"fw-normal"}>Add App</span>
                                </div>
                                <div className={"card-body"}>
                                    {nameError && nameError !== "Required" ? <small
                                        className={"text-danger"}>{nameError}</small> : ""}
                                    <Formik
                                        initialValues={{
                                            name: '',
                                            description: ''
                                        }}
                                        validationSchema={formValidations}
                                        onSubmit={values => PostData(values)}
                                    >
                                        {formik => (
                                            <Form>
                                                <InputField label={"Name"} type={"text"} name={"name"}
                                                            placeholder={"Enter Name*"}
                                                />
                                                <TextField label={"Description"} type={"text"} name={"description"}
                                                           placeholder={"Enter Description*"} rows={8}/>
                                                <Button type={"submit"} title={"Add"} classes={"btn btn-primary btn-sm"}
                                                        loading={isSubmitting}/>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AddApp;
