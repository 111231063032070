import React from 'react';
import DashboardApp from "./DashboardApp";

const Dashboard = () => {


    return (
        <>
            <DashboardApp></DashboardApp>
        </>
    );
}

export default Dashboard;
