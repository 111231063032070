import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {config} from "../data/constants";
import {fetchGet} from "../utils/index";
import AppCard from "./AppCard";

const Design = () => {
    const [apps, setApps] = useState({});
    useEffect(() => {
        let url = config.API_URL + '/api/v1/app/';
        let accessToken = localStorage.getItem("jwt");
        fetchGet(url, accessToken).then(res => {
            if (res.success) {
                setApps({...res.data["user_app_details"]});
            }
        })
    }, []);
    return (
        <></>
    );
}

export default Design;