const {getConfig} = require("../config");
let _config = {
    cookieDomain: "",
    cookieExpiryDays: 60,
    JWT_SECRET: "BsOHifo50O",
    message: {
        GENERIC_SUCCESS_MESSAGE: "Updated successfully.",
        GENERIC_ERROR_MESSAGE: "There was an error while updating.",
    },
    HTTP_STATUS_CODE: {
        OK: 200,
        BAD_REQUEST: 400,
        UNAUTHORISED: 401,
        INTERNAL_SERVER_ERROR: 500,
    }
};
let config = Object.assign({}, _config, getConfig());

module.exports = {
    config: config
};