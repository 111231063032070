import React from "react";

export const Button = ({title, loading, classes, type}) => {
    return (
        <button
            type={type}
            className={classes + `${loading ? " disabled" : ""}`}>
            {loading ? <div className="spinner-border text-dark spinner-dimension" role="status"/> : title}
        </button>
    );
}