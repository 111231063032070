const productionConfig = require("./production");
const developmentConfig = require("./development");
const getConfig = () => {
    const customEnv = process.env.NODE_ENV || "development";
    if (customEnv === "production") {
        console.log("Production");
        return productionConfig;
    }
    if (customEnv === "development") {
        console.log("Local");
        return developmentConfig;
    }
};

module.exports = {
    getConfig: getConfig,
};
