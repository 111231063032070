import React, {createContext, useContext, useEffect, useReducer} from "react";
import {BrowserRouter, Route, Switch, useHistory, useLocation} from "react-router-dom";
import {initialState, reducer} from "../reducers/useReducer";
import Dashboard from "../components/Dashboard";
import NotFoundPage from "../components/NotFoundPage";
import SignIn from "../components/SignIn";
import SignUp from "../components/SignUp";
import AddApp from "../components/AddApp";
import UserProfile from "../components/UserProfile";
import AppDetail from "../components/AppDetail";
import Header from "../components/Header";
import Design from "../components/Design";
import FaceRecognise from "../components/FaceRecognise";
import FaceRegister from "../components/FaceRegister";

export const UserContext = createContext();

const Routing = () => {
    const history = useHistory();
    const {state, dispatch} = useContext(UserContext);
    let location = useLocation();
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"));
        if (user) {
            dispatch({type: "USER", payload: user});
            if (location.pathname in {"/sign-in": 0, "/sign-up": 1, "/": 2}) {
                history.push("/dashboard");
            } else {
                history.push(location.pathname);
            }
        } else if (location.pathname in {
            "/face-recognise": 0,
            "/face-register": 1,
            "/design": 2,
            "/sign-in": 3,
            "/sign-up": 4
        }) {
            history.push(location.pathname)
        } else if (location.pathname in {"/": 0, "/dashboard": 1}) {
            history.push("/sign-in");
        }
    }, []);
    return (
        <Switch>
            <Route path={"/dashboard"} exact={true} component={Dashboard}/>
            <Route path={"/sign-in"} exact={true} component={SignIn}/>
            <Route path={"/sign-up"} exact={true} component={SignUp}/>
            <Route path={"/add-app"} exact={true} component={AddApp}/>
            <Route path={"/profile"} exact={true} component={UserProfile}/>
            <Route path={"/app-detail/:id"} exact={true} component={AppDetail}/>
            <Route path={"/design"} exact={true} component={Design}/>
            <Route path={"/face-recognise"} exact={true} component={FaceRecognise}/>
            <Route path={"/face-register"} exact={true} component={FaceRegister}/>
            <Route component={NotFoundPage}/>
        </Switch>
    );
};

const AppRouter = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <UserContext.Provider value={{state, dispatch}}>
            <BrowserRouter>
                <div className={"bg-off-white h-100vh"}>
                    <Header/>
                    <Routing/>
                </div>
            </BrowserRouter>
        </UserContext.Provider>
    );
};

export default AppRouter;
