import React, {useEffect, useState} from "react";
import {config} from "../data/constants";
import {fetchGet} from "../utils/index";
import {Link} from "react-router-dom";
import TimeAgo from 'react-timeago';
import {OverlayTrigger, Tooltip} from "react-bootstrap";

const AddDetail = props => {
    const [appDetails, setAppDetails] = useState({});
    const [isCopied, setIsCopied] = useState(false);
    useEffect(() => {

        let id = props.match.params.id;
        let url = config.API_URL + "/api/v1/app/" + id + "/";
        fetchGet(url).then(res => {
            if (res.success) {
                console.log("data ", res.data);
                setAppDetails({...res.data["app_details"]});
            }
        });
    }, []);
    return (
        <div className={"container-fluid"}>
            <div className={"row"}>
                <div className={"col-12"}>
                    <Link to={"/dashboard"} className={"btn btn-sm btn-outline-dark float-end"}>
                        All apps
                    </Link>
                </div>
                <div className={"col-4"}>
                    <div className="card mb-1 rounded-3 border-0 shadow-sm">
                        <div className="card-body p-0">
                            <div className="p-2">
                                <div>
                                    <span>{appDetails.name}</span>
                                </div>
                                <div className="text-dark">{appDetails.description}</div>
                                <OverlayTrigger
                                    placement="top"
                                    delay={{show: 100, hide: 200}}
                                    overlay={<Tooltip>{isCopied ? "Copied" : "Click to Copy"} </Tooltip>}
                                >
                                    <div className="text-success show-hand" onClick={() => {
                                        navigator.clipboard.writeText(appDetails.auth_key);
                                        setIsCopied(true);
                                    }}>{appDetails.auth_key}</div>
                                </OverlayTrigger>
                                <div className="text-secondary">
                                    <small><TimeAgo date={appDetails.created_at}/></small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddDetail;
