import React, {useContext} from "react";
import {Link, useHistory} from "react-router-dom";
import {UserContext} from "../router/AppRouter";
import {Dropdown} from "react-bootstrap";

const Header = () => {
    const {state, dispatch} = useContext(UserContext);
    const history = useHistory();

    return (
        <>
            {state ? (
                <header>
                    <nav className="navbar navbar-expand-lg navbar-light mb-2">
                        <div className="container-fluid">
                            <h3>
                                <Link to={"/dashboard"} className={"text-decoration-none text-dark"}>
                                    Visage
                                </Link>
                            </h3>
                            <div className="collapse navbar-collapse justify-content-end">
                                <ul className="navbar-nav">
                                    <Dropdown>
                                        <Dropdown.Toggle
                                            variant="outline-none"
                                            bsPrefix={"none"}
                                        >
                                            <img
                                                alt={"user-profile-icon"}
                                                className={"w-24px show-hand"}
                                                src={
                                                    "https://image.flaticon.com/icons/png/512/16/16363.png"
                                                }
                                            />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item as={Link} to={"/profile"}>
                                                Profile
                                            </Dropdown.Item>
                                            <Dropdown.Item as={Link} to={"/app"}>
                                                App
                                            </Dropdown.Item>
                                            <Dropdown.Divider/>
                                            <Dropdown.Item
                                                href=""
                                                onClick={() => {
                                                    localStorage.clear();
                                                    dispatch({type: "CLEAR"});
                                                    history.push("/sign-in");
                                                }}
                                            >
                                                Logout
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </header>
            ) : (
                ""
            )}
        </>
    );
};

export default Header;
