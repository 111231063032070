import React, {useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {fetchPost} from "../utils/index";
import {config} from "../data/constants";
import sign_up_card from "../static/images/register.png";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import {InputField} from "./common/InputField";
import {Button} from "./common/Button";

const SignUp = () => {
    const history = useHistory();
    const [isErrorMessage, setIsErrorMessage] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const formValidation = Yup.object({
        first_name: Yup.string().required("First Name is Required"),
        middle_name: Yup.string(),
        last_name: Yup.string().required("Last Name is Required"),
        email: Yup.string().email("Invalid Email").required("Email is Required"),
        password: Yup.string().required("Password is Required")
    });

    const PostData = (data) => {
        setIsSubmitting(true);
        let url = config.API_URL + "/api/v1/sign-up/";
        fetchPost(url, data).then(res => {
            if (res.success) {
                history.push("/sign-in");
            } else {
                setIsErrorMessage(res.message);
                setIsSubmitting(false);
            }
        });

    };

    return (
        <div className="d-flex align-items-center min-vh-100 py-3 py-md-0">
            <div className={"container"}>
                <div className={"row g-0 d-flex justify-content-center align-items-center"}>
                    <div className={"col-12 col-lg-8"}>
                        <div
                            className={
                                "row g-0 bg-white shadow rounded-2 d-flex justify-content-center align-items-center"
                            }
                        >
                            <div className={"col-12 col-lg-6"}>
                                <img className={"w-100"} src={sign_up_card} alt={"login_png"}/>
                            </div>
                            <div className={"col-12 col-lg-6 px-5"}>
                                <h1>Visage</h1>
                                <h5>Create Your Account</h5>
                                {isErrorMessage ?
                                    <small><p className={"p-0 text-danger"}>{isErrorMessage}</p></small> : ""}
                                <Formik initialValues={{
                                    first_name: "",
                                    middle_name: "",
                                    last_name: "",
                                    email: "",
                                    password: ""
                                }} onSubmit={values => PostData(values)}
                                        validationSchema={formValidation}>
                                    {formik => (
                                        <Form>
                                            <InputField label={null} type={"text"} name={"first_name"}
                                                        placeholder={"Enter First Name*"}/>
                                            <InputField label={null} type={"text"} name={"middle_name"}
                                                        placeholder={"Enter Middle Name"}/>
                                            <InputField label={null} type={"text"} name={"last_name"}
                                                        placeholder={"Enter Last Name*"}/>
                                            <InputField label={null} type={"email"} name={"email"}
                                                        placeholder={"Enter Email*"}/>
                                            <InputField label={null} type={"password"} name={"password"}
                                                        placeholder={"Enter Password*"}/>
                                            <div className={"form-row"}>
                                                <div className={""}>
                                                    <Button type={"submit"} title={"Sign-Up"}
                                                            classes={"btn btn-sm btn-primary"}
                                                            loading={isSubmitting}/>
                                                    <div className={"py-3"}>
                                                        <Link
                                                            to={"/sign-in"}
                                                            className={"text-dark text-decoration-none"}
                                                        >
                                                            Have an account? <span
                                                            className={"text-decoration-underline"}>Sign-In</span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignUp;
