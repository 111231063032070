import fetch from "isomorphic-unfetch";
import {config} from "../data/constants";
import {logout} from "./actions";

export const getHeaders = () => {
    const headers = {
        "Content-Type": "application/json",
    };
    var accessToken = localStorage.getItem("jwt");
    if (accessToken) {
        headers["Auth-Token"] = accessToken;
    }
    return headers;
};

const tryJson = async (res) => {
    try {
        return await res.json();
    } catch (_error) {
        return undefined;
    }
};

export const fetchPost = async (url, data) => {
    return await fetch(url, {
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify(data),
    })
        .then(async (resp) => {
            if (resp.status === config.HTTP_STATUS_CODE.UNAUTHORISED) {
                logout(null, false);
            }
            if (!resp.ok) {
                const body = await tryJson(resp);
                throw new Error(body ? body.message : "Something went wrong");
            }
            return await resp.json();
        })
        .catch((err) => err);
};

export const fetchGet = async (url) => {
    return await fetch(url, {
        method: "GET",
        headers: getHeaders(),
    })
        .then(async (resp) => {
            if (resp.status === config.HTTP_STATUS_CODE.UNAUTHORISED) {
                logout(null, false);
            }
            if (!resp.ok) {
                const body = await tryJson(resp);
                throw new Error(body ? body.message : "Something went wrong");
            }
            return await resp.json();
        })
        .catch((err) => err);
};

export const fetchGetRunSample = async (url, id, filter) => {
    return await fetch(`${url}/${id}?${filter}`, {
        method: "GET",
        headers: getHeaders(),
    })
        .then(async (resp) => {
            if (resp.status === config.HTTP_STATUS_CODE.UNAUTHORISED) {
                logout(null, false);
            }
            if (!resp.ok) {
                const body = await tryJson(resp);
                throw new Error(body ? body.message : "Something went wrong");
            }
            return await resp.json();
        })
        .catch((err) => err);
};
