import React, {useEffect, useState, useContext} from "react";
import {config} from "../data/constants";
import {fetchPost, fetchGet} from "../utils/index";
import {UserContext} from "../router/AppRouter";
import * as Yup from "yup";
import {Formik, Form} from "formik";
import {InputField} from "./common/InputField";
import {Button} from "./common/Button";
import backArrowIcon from "../static/images/back-arrow.png";
import editIcon from "../static/images/edit.png";

const UserProfile = () => {
    const {state} = useContext(UserContext);
    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [isEdit, setIsEdit] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [isUpdated, setIsUpdated] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        let url = config.API_URL + "/api/v1/profile/";
        fetchGet(url).then(res => {
            if (res.success) {
                setFirstName(res.data["user_details"]["first_name"]);
                setMiddleName(res.data["user_details"]["middle_name"]);
                setLastName(res.data["user_details"]["last_name"]);
                setEmail(res.data["user_details"]["email"]);
            }
        });
    }, [isUpdated]);

    const submit = (data) => {
        setErrorMessage(null);
        setIsSubmitting(true);
        let url = config.API_URL + "/api/v1/profile/";
        fetchPost(url, data).then(res => {
            if (res.success) {
                setIsUpdated(!isUpdated);
                setIsEdit(false);
                setIsSubmitting(false);
            } else {
                setErrorMessage(res.message);
                setIsSubmitting(false);
            }
        });
    };

    return (
        <>
            <section>
                <div className={"container-fluid"}>
                    <div className={"row justify-content-start d-flex"}>
                        <div className={"col-4"}>
                            <div className={"card rounded shadow"}>
                                <div className={"card-header position-relative"}>
                                    <span className={"fw-normal"}>User Details</span>

                                    <span className={"position-absolute edit-user-profile"}>
                                            {!isEdit ?
                                                <img
                                                    className={"w-16px show-hand"}
                                                    src={editIcon}
                                                    onClick={() => setIsEdit(true)}
                                                />
                                                :
                                                <img
                                                    className={"w-16px show-hand"}
                                                    src={backArrowIcon}
                                                    onClick={() => setIsEdit(false)}
                                                />}
                                        </span>
                                </div>
                                <div className={"card-body"}>
                                    {isEdit ? (
                                        <EditView
                                            onSubmit={submit}
                                            firstName={firstName}
                                            middleName={middleName}
                                            lastName={lastName}
                                            errorMessage={errorMessage}
                                            isSubmitting={isSubmitting}
                                        />
                                    ) : (
                                        <NormalView
                                            firstName={firstName}
                                            middleName={middleName}
                                            lastName={lastName}
                                            email={email}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

function NormalView(props) {
    return (
        <div>
            <p>First Name : {props.firstName}</p>
            <p>Middle Name : {props.middleName}</p>
            <p>Last Name : {props.lastName}</p>
            <p>Email : {props.email}</p>
        </div>
    );
}

function EditView(props) {

    const formValidations = Yup.object({
        first_name: Yup.string().required("First Name Required"),
        middle_name: Yup.string(),
        last_name: Yup.string().required("Last Name Required"),
        password: Yup.string()
    });

    return (
        <>
            {props.errorMessage ? <small className={"text-danger"}>{props.errorMessage}</small> : ""}
            <Formik
                initialValues={{
                    first_name: props.firstName,
                    middle_name: props.middleName,
                    last_name: props.lastName,
                    password: ""
                }}
                onSubmit={values => {
                    props.onSubmit(values);
                }}
                validationSchema={formValidations}
            >
                {formik => (
                    <Form>
                        <InputField label={"First Name*"} type={"text"} name={"first_name"}
                                    placeholder={"Enter First Name"}
                        />
                        <InputField label={"Middle Name"} type={"text"} name={"middle_name"}
                                    placeholder={"Enter Middle Name"}
                        />
                        <InputField label={"Last Name*"} type={"text"} name={"last_name"}
                                    placeholder={"Enter Last Name"}
                        />
                        <InputField label={"Password"} type={"text"} name={"password"}
                                    placeholder={"Leave blank to keep unchanged"}
                        />
                        <Button type={"submit"} title={"Edit"} classes={"btn btn-primary btn-sm"}
                                loading={props.isSubmitting}/>
                    </Form>
                )}
            </Formik>
        </>
    );
}

export default UserProfile;
