import React from "react";
import {config} from "../data/constants";
import fetch from "isomorphic-unfetch";
import {logout} from "../utils/actions";

export default class FaceRegister extends React.Component {
    constructor(props) {
        super(props);

        this.cameraNumber = 0;

        this.state = {
            imageDataURL: null,
            faceRecognitionModel: null,
            faceDetectorModel: null,
            image_key: null
        };
    }

    initializeMedia = async () => {
        this.setState({imageDataURL: null});
        if (!("mediaDevices" in navigator)) {
            navigator.mediaDevices = {};
        }

        if (!("getUserMedia" in navigator.mediaDevices)) {
            navigator.mediaDevices.getUserMedia = (constraints) => {
                let getUserMedia =
                    navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

                if (!getUserMedia) {
                    return Promise.reject(new Error("getUserMedia Not Implemented"));
                }

                return new Promise((resolve, reject) => {
                    getUserMedia.call(navigator, constraints, resolve, reject);
                });
            };
        }
        if (navigator.mediaDevices) {
            navigator.mediaDevices.getUserMedia({video: true}).then((stream) => {
                this.player.srcObject = stream;
            }).catch((error) => {
                document.body.textContent = 'Could not access the camera. Error: ' + error.name;
            });
        }
    }

    reset = () => {
        this.initializeMedia();
    }

    capturePicture = () => {
        let canvas = document.createElement("canvas");
        canvas.width = this.player.videoWidth;
        canvas.height = this.player.videoHeight;
        let context = canvas.getContext("2d");
        context.drawImage(this.player, 0, 0, canvas.width, canvas.height);
        this.player.srcObject.getVideoTracks().forEach((track) => {
            track.stop();
        });

        this.setState({imageDataURL: canvas.toDataURL()}, async () => {
            let url = config.API_URL + "/api/v1/demo-register-face/";
            let data = {
                "image_64": this.state.imageDataURL,
                "face_recognition_model": this.state.faceRecognitionModel,
                "face_detector_model": this.state.faceDetectorModel,
                "image_key": this.state.image_key
            }
            let response = await fetch(url, {
                method: "POST",
                headers: {
                    "Auth-Key": "06aeb376c04bbd3c4a26366d482cb1ee04f687293eb1463db9bedc30af18ca78",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data),
            }).then(async (resp) => {
                if (resp.status === 401) {
                    logout(null, false);
                }
                if (!resp.ok) {
                    const body = await this.tryJson(resp);
                    throw new Error(body ? body.message : "Something went wrong");
                }
                return await resp.json();
            }).catch((err) => err);
            if (response.status === 200) {
                alert(response.message);
                this.reset();
            }
        });
    }

    render() {
        const playerORImage = Boolean(this.state.imageDataURL) ? (
            <img src={this.state.imageDataURL} alt="cameraPic"/>
        ) : (
            <video
                ref={(reference) => {
                    this.player = reference;
                }}
                autoPlay
            />
        );
        return (
            <>
                <div className="App">
                    {playerORImage}
                    <div>
                        <input name={"image_key"} onChange={(event) => {
                            this.setState({image_key: event.target.value})
                        }}/>
                        <div className="controller1 d-flex">
                            <h3>Face recognition model</h3>
                            <div className={"p-2"}>
                                <input type="radio" name="face_rec_model" value="ArcFace" onChange={(event) => {
                                    this.setState({faceRecognitionModel: event.target.value})
                                }}/>
                                <label>ArcFace</label>
                            </div>
                            <div className={"p-2"}>
                                <input type="radio" name="face_rec_model" value="Dlib" onChange={(event) => {
                                    this.setState({faceRecognitionModel: event.target.value})
                                }}/>
                                <label>Dlib</label>
                            </div>

                            <div className={"p-2"}>
                                <input type="radio" name="face_rec_model" value="Facenet" onChange={(event) => {
                                    this.setState({faceRecognitionModel: event.target.value})
                                }}/>
                                <label>Facenet</label>
                            </div>

                            <div className={"p-2"}>
                                <input type="radio" name="face_rec_model" value="Facenet512" onChange={(event) => {
                                    this.setState({faceRecognitionModel: event.target.value})
                                }}/>
                                <label>Facenet512</label>
                            </div>

                            <div className={"p-2"}>
                                <input type="radio" name="face_rec_model" value="DeepFace" onChange={(event) => {
                                    this.setState({faceRecognitionModel: event.target.value})
                                }}/>
                                <label>DeepFace</label>
                            </div>
                            <div className={"p-2"}>
                                <input type="radio" name="face_rec_model" value="OpenFace" onChange={(event) => {
                                    this.setState({faceRecognitionModel: event.target.value})
                                }}/>
                                <label>OpenFace</label>
                            </div>

                            <div className={"p-2"}>
                                <input type="radio" name="face_rec_model" value="VGG-Face" onChange={(event) => {
                                    this.setState({faceRecognitionModel: event.target.value})
                                }}/>
                                <label>VGG-Face</label>
                            </div>
                        </div>
                        <div className="controller2 d-flex">
                            <h3>Face detector model</h3>
                            <div className={"p-2"}>
                                <input type="radio" name="face_detector_model" value="opencv" onChange={(event) => {
                                    this.setState({faceDetectorModel: event.target.value})
                                }}/>
                                <label>opencv</label>
                            </div>

                            <div className={"p-2"}>
                                <input type="radio" name="face_detector_model" value="mtcnn" onChange={(event) => {
                                    this.setState({faceDetectorModel: event.target.value})
                                }}/>
                                <label>mtcnn</label>
                            </div>
                            <div className={"p-2"}>
                                <input type="radio" name="face_detector_model" value="dlib" onChange={(event) => {
                                    this.setState({faceDetectorModel: event.target.value})
                                }}/>
                                <label>dlib</label>
                            </div>

                            <div className={"p-2"}>
                                <input type="radio" name="face_detector_model" value="ssd" onChange={(event) => {
                                    this.setState({faceDetectorModel: event.target.value})
                                }}/>
                                <label>ssd</label>

                            </div>
                            {/*<div className={"p-2"}>*/}
                            {/*    <input type="radio" name="face_detector_model" value="retinaface" onChange={(event) => {*/}
                            {/*        this.setState({faceDetectorModel: event.target.value})*/}
                            {/*    }}/>*/}
                            {/*    <label>retinaface</label>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    <button className={"btn btn-primary"} onClick={this.capturePicture}>Submit</button>
                    <button className={"btn btn-secondary mx-5"} onClick={this.reset}>Reset</button>
                </div>
            </>
        );
    }

    componentDidMount() {
        this.initializeMedia();
    }
}