import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { config } from "../data/constants";
import { fetchGet } from "../utils/index";
import AppCard from "./AppCard";

function DashboardApp() {
  const [apps, setApps] = useState({});
  useEffect(() => {
    let url = config.API_URL + "/api/v1/app/";
    fetchGet(url).then(res => {
      if (res.success) {
        setApps({ ...res.data["user_app_details"] });
      }
    });
  }, []);
  return (
    <div className={"container-fluid"}>
      <div className={"row"}>
        <div className={"col-12"}>
          <Link
            to={"/add-app"}
            className={"btn btn-sm btn-outline-dark float-end"}
          >
            Add
          </Link>
        </div>
        {Object.keys(apps).map(key => (
          <div className={"col-12 col-lg-2 my-2"} key={apps[key].id} >
            <AppCard data={apps[key]} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default DashboardApp;
