import React, {useState, useContext} from "react";
import {Link, useHistory} from "react-router-dom";
import {config} from "../data/constants";
import {fetchPost} from "../utils/index";
import {UserContext} from "../router/AppRouter";
import sign_in_card from "../static/images/login.png";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import {InputField} from "./common/InputField";
import {Button} from "./common/Button";

const SignIn = () => {
    const {dispatch} = useContext(UserContext);
    const history = useHistory();
    const [isErrorMessage, setIsErrorMessage] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);


    const formValidations = Yup.object({
        email: Yup.string().email("Invalid Email").required("Email is Required"),
        password: Yup.string().required("Password is Required")
    });

    const PostData = (data) => {
        setIsSubmitting(true);
        let url = config.API_URL + "/api/v1/login/";
        fetchPost(url, data).then(res => {
            if (res.success) {
                localStorage.setItem("jwt", res["auth_token"]);
                localStorage.setItem("user", JSON.stringify(res.data["user_details"]));
                dispatch({type: "USER", payload: res.data["user_details"]});
                history.push("/dashboard");
            } else {
                setIsErrorMessage(res.message);
                setIsSubmitting(false);
            }
        });
    };

    return (
        <div className="d-flex align-items-center min-vh-100 py-3 py-md-0">
            <div className={"container"}>
                <div className={"row g-0 d-flex justify-content-center align-items-center"}>
                    <div className={"col-12 col-lg-8"}>
                        <div
                            className={"row g-0 bg-white shadow rounded-2 d-flex justify-content-center align-items-center"
                            }>
                            <div className={"col-12 col-lg-6"}>
                                <img className={"w-100"} src={sign_in_card} alt={"login_png"}/>
                            </div>
                            <div className={"col-12 col-lg-6 px-5"}>
                                <h1>Visage</h1>
                                <h5>Sign in to your account</h5>
                                {isErrorMessage ?
                                    <small><p className={"p-0 text-danger"}>{isErrorMessage}</p></small> : ""}
                                <Formik
                                    initialValues={{
                                        email: '',
                                        password: ''
                                    }}
                                    validationSchema={formValidations}
                                    onSubmit={values => PostData(values)}
                                >
                                    {formik => (
                                        <Form>
                                            <InputField
                                                label={null}
                                                type={"email"}
                                                name={"email"}
                                                placeholder={"Enter Email*"}
                                            />
                                            <InputField
                                                label={null}
                                                type={"password"}
                                                name={"password"}
                                                placeholder={"Enter password*"}
                                            />
                                            <div className={"form-row"}>
                                                <div className={""}>
                                                    <Button type={"submit"} title={"Sign-In"}
                                                            classes={"btn btn-sm btn-primary mt-2"}
                                                            loading={isSubmitting}/>
                                                    <div className={"pt-3"}>
                                                        <Link
                                                            to={"/sign-up"}
                                                            className={"text-dark text-decoration-none"}>
                                                            Don't have an account? <span
                                                            className={"text-decoration-underline"}>Sign-Up</span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignIn;
